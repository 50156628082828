import { $themeBreakpoints } from '@themeConfig'
import {getStorage, setStorage, removeStorage} from "@/libs/utils"

let localLanguage = (navigator.language || navigator.browserLanguage).toLowerCase()
localLanguage = localLanguage=='zh-cn' ? "zh_CN" : 'en'

export default {
	namespaced: true,
	state: {
		windowWidth: 0,
		shallShowOverlay: false,
		locale: getStorage('LOCALE') || localLanguage,
		userInfo: getStorage('USER_INFO'),

		programPlayMs: 0,
		programPlaying: false,
		programVideoPlaying: false,
		programMusicPlaying: false,
		programPlaySpeed: '1.0',
	},
	getters: {
		currentBreakPoint: state => {
			const { windowWidth } = state
			if (windowWidth >= $themeBreakpoints.xl) return 'xl'
			if (windowWidth >= $themeBreakpoints.lg) return 'lg'
			if (windowWidth >= $themeBreakpoints.md) return 'md'
			if (windowWidth >= $themeBreakpoints.sm) return 'sm'
			return 'xs'
		},
	},
	mutations: {
		UPDATE_WINDOW_WIDTH(state, val) {
			state.windowWidth = val
		},
		TOGGLE_OVERLAY(state, val) {
			state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
		},
		SET_LOCALE (state, val) {
			state.locale = val
			setStorage('LOCALE',val)
		},
		SET_USER_INFO (state, val) {
			state.userInfo = val
			if(!val){
				removeStorage('USER_INFO')
			}else{
				setStorage('USER_INFO',val)
			}
		},
		setProgramPlayMs: (state, programPlayMs) => {
			state.programPlayMs = programPlayMs
        },
		setProgramPlaying: (state, programPlaying) => {
			state.programPlaying = programPlaying
        },
		setProgramVideoPlaying: (state, programVideoPlaying) => {
			state.programVideoPlaying = programVideoPlaying
        },
		setProgramMusicPlaying: (state, programMusicPlaying) => {
			state.programMusicPlaying = programMusicPlaying
        },
		setProgramPlaySpeed: (state, value) => {
			state.programPlaySpeed = value
        },
	},
	actions: {},
}
