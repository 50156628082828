<template>
	<div class="htmleaf-container">
		<div class="loader">
			<b-spinner variant="primary" />
		</div>
	</div>
</template>
<script>

import {
  	BSpinner
} from 'bootstrap-vue'
export default {
	name: "LoadingDirective",
	data() {
		return {};
	},
	props: {},
	components: { BSpinner },
	methods: {},
	created() {},
	mounted() {},
	beforeDestroy() {},
};
</script>
 
<style lang ="scss"  scoped>
/* .container-loading {
	height: 100%;
	width: 100%;
} */
.htmleaf-container {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 12px;
 
	min-height: 60px;
	background-color: rgba(255, 255, 255, 0.8);
}
 
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 60px;
}
 
 
</style>