import Vue from 'vue'
import axios from 'axios'
import config from './config'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: config.baseUrl,
    // timeout: 6000,
    // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default  axiosIns
