import config from './config'
let CryptoJS = require("crypto-js");
// 设置Storage
export function setStorage(key, value) {
    if ((typeof (value)).toLowerCase() == 'object') {
        value = JSON.stringify(value)
    }
    key = 'FRUITSCREEN_'+key
    window.localStorage.setItem(key, value);
}
// 获取Storage
export function getStorage(key) {
    key = 'FRUITSCREEN_'+key
    let value = window.localStorage.getItem(key);
    if (value && ((value.indexOf('{') >= 0 && value.indexOf('}') > 0) || ((value.indexOf('[') >= 0 && value.indexOf(']') > 0)))) {
        return JSON.parse(value)
    }
    return value
}
// 删除Storage
export function removeStorage(key) {
    key = 'FRUITSCREEN_'+key
    window.localStorage.removeItem(key);
}
// 清空Storage
export function clearStorage() {
    window.localStorage.clear();
}
export function readChunked(file, chunkCallback, endCallback) {
    let fileSize = file.size;
    let chunkSize = 100 * 1024 * 1024; // 4MB
    let offset = 0;
    let reader = new FileReader();
    reader.onload = function () {
        if (reader.error) {
            endCallback(reader.error || {});
            return;
        }
        offset += reader.result.length;
        // callback for handling read chunk
        // TODO: handle errors
        chunkCallback(reader.result, offset, fileSize);
        if (offset >= fileSize) {
            endCallback(null);
            return;
        }
        readNext();
    };

    reader.onerror = function (err) {
        endCallback(err || {});
    };

    function readNext() {
        let fileSlice = file.slice(offset, offset + chunkSize);
        reader.readAsBinaryString(fileSlice);
    }

    readNext();
}
export function getFileMd5(file) {
    return new Promise((resolve, reject) => {
        let md5 = CryptoJS.algo.MD5.create();
        readChunked(file, (chunk, offs, total) => {
            md5.update(CryptoJS.enc.Latin1.parse(chunk));
        }, err => {
            if (err) {
                reject(err);
            } else {
                // TODO: Handle errors
                var hash = md5.finalize();
                var hashHex = hash.toString(CryptoJS.enc.Hex);
                console.log(hashHex)
                resolve(hashHex);
            }
        });
    });
}
export function bytesToSize(bytes) {
    bytes = +bytes
    if (bytes === 0) return '0 B';
    let k = 1024,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return (bytes / Math.pow(k, i)).toPrecision(4) + ' ' + sizes[i];
}

export const deepCopy = (o) => {
    if (o instanceof Array) {
        var n = [];
        for (var i = 0; i < o.length; ++i) {
            n[i] = deepCopy(o[i]);
        }
        return n;
    } else if (o instanceof Object) {
        var n = {}
        for (var i in o) {
            n[i] = deepCopy(o[i]);
        }
        return Object.assign({}, n);
    } else {
        return o;
    }
}

export function formatDate(date, fmt){
    var o = {
		"M+": date.getMonth() + 1, //月份
		"D+": date.getDate(), //日
		"h+": date.getHours(), //小时
		"m+": date.getMinutes(), //分
		"s+": date.getSeconds(), //秒
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度
		"S": date.getMilliseconds() //毫秒
	};
	if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}

export function fullMaterialUrl (md5, suffix) {
    return config.ossUrl + md5 + "." + suffix;
}
export function secondsToTimeObj(seconds) {
    let hour = Math.floor(seconds / 3600)
    let minute = Math.floor((seconds - hour * 3600) / 60)
    let sec = seconds - hour * 3600 - minute * 60
    hour = hour < 10 ? '0' + hour : hour
    minute = minute < 10 ? '0' + minute : minute
    sec = sec < 10 ? '0' + sec : sec
    if(parseFloat(sec)>parseInt(sec)){
        sec = sec.toFixed(3)
    }
    return {
        "HH": hour + '',
        "mm": minute + '',
        "ss": sec + '',
    }
}

export function formatCycle (value) {
    let cycle = value.toString(2).split('')
    let len = cycle.length
    let differ= []
    for(let i=0;i<8-len;i++){
        differ.push('0')
    }
    cycle = [...differ,...cycle]
    let temp = []
    cycle.forEach((item,index)=>{
        if(item==='1'){
            temp.push(config.cycleList.find(item=>item.id==index))
        }
    })
    return temp.sort((a,b) => b.id-a.id)
}