const isProduction = process.env.NODE_ENV === 'production'
const isHttps = window.location.protocol === "https:"
const httpStr = isHttps?'https:': 'http:'
const userUrl = `${httpStr}//u.douya.link`
const serverUrl = `${httpStr}//server.douya.link`
const assetsUrl = `${httpStr}//assets.douya.link`
const userApi = isProduction ? userUrl : '/uapi'
const serverApi = isProduction ? serverUrl : '/sapi'
const assetsApi = isProduction ? assetsUrl : '/aapi'
const imgType = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
const videoType = ['mp4']
const textType = ['txt']
const audioType = ['mp3', 'wav', 'wmv']
export default {
    userUrl,
    serverUrl,
    assetsUrl,
    userApi,
    serverApi,
    assetsApi,
    defaultAvatar: require('@/assets/images/avatars/avatar-default.png'),
    fileTypes: {
        imgType,
        videoType,
        textType,
        audioType
    },
    ossUrl: 'https://lingxingyu-cloud.oss-cn-shenzhen.aliyuncs.com/',
    cycleList: [
        {id:7, name: '周一'},
        {id:6, name: '周二'},
        {id:5, name: '周三'},
        {id:4, name: '周四'},
        {id:3, name: '周五'},
        {id:2, name: '周六'},
        {id:1, name: '周日'},
    ],
    animList: [{
        id: 0,
        name: 'Random',
        class: 'random'
    },{
        id: 1,
        name: 'FadeIn',
        class: 'fadeIn'
    },{
        id: 2,
        name: 'MoveIn',
        class: 'moveIn'
    },{
        id: 3,
        name: 'ScaleIn',
        class: 'scaleIn'
    },{
        id: 4,
        name: 'RotateIn',
        class: 'rotateIn'
    },{
        id: 5,
        name: 'RotateScaleIn',
        class: 'roteteScaleIn'
    },{
        id: 6,
        name: 'MoveFadeIn',
        class: 'moveFadeIn'
    }]
}