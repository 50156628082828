import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import config from './libs/config'
Vue.prototype.$config = config
Vue.prototype.logoutAction = function () {
	store.commit('app/SET_USER_INFO',null)
	router.replace('/login')
}

//自定义loading指令 v-loading
import DirectiveLoading from '@/directives/loading' 
Vue.use( DirectiveLoading )

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
