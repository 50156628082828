import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
	// mode: 'hash',
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/admin',
			name: 'admin',
			component: () => import('@/views/admin/ecommerce/Ecommerce.vue'),
			meta: {
				
			},
		},
		{
			path: '/myInfo',
			name: 'myInfo',
			component: () => import('@/views/admin/MyInfo.vue'),
			meta: {
				pageTitle: 'My Info',
				breadcrumb: [
					{
						text: 'My Info',
						active: true,
					},
				],
			},
		},
		{
			path: '/device',
			name: 'device',
			component: () => import('@/views/admin/device/List.vue'),
			meta: {
				pageTitle: 'Device',
				breadcrumb: [
					{
						text: 'Device',
						active: true,
					},
				],
			},
		},
		{
			path: '/deviceDetail',
			name: 'deviceDetail',
			component: () => import('@/views/admin/device/Details.vue'),
			meta: {
				navActiveLink: 'device',
				pageTitle: 'Device Details',
				breadcrumb: [
					{
						text: 'Device',
						to: '/device',
					},
					{
						text: 'Device Details',
						active: true,
					},
				],
			},
		},
		{
			path: '/switchSchedule',
			name: 'switchSchedule',
			component: () => import('@/views/admin/device/SwitchSchedule.vue'),
			meta: {
				navActiveLink: 'device',
				pageTitle: 'Switching Schedule',
				breadcrumb: [
					{
						text: 'Device',
						to: '/device',
					},
					{
						text: 'Switching Schedule',
						active: true,
					},
				],
			},
		},
		{
			path: '/programSchedule',
			name: 'programSchedule',
			component: () => import('@/views/admin/device/ProgramSchedule.vue'),
			meta: {
				navActiveLink: 'device',
				pageTitle: 'Program Schedule',
				breadcrumb: [
					{
						text: 'Device',
						to: '/device',
					},
					{
						text: 'Program Schedule',
						active: true,
					},
				],
			},
		},
		{
			path: '/deviceAd',
			name: 'deviceAd',
			component: () => import('@/views/admin/device/ad/Ad.vue'),
			meta: {
				navActiveLink: 'device',
				pageTitle: 'Advertising Window',
				breadcrumb: [
					{
						text: 'Device',
						to: '/device',
					},
					{
						text: 'Advertising Window',
						active: true,
					},
				],
			},
		},
		{
			path: '/deviceAdDesc',
			name: 'deviceAdDesc',
			component: () => import('@/views/admin/device/ad/DescEdit.vue'),
			meta: {
				navActiveLink: 'device',
				pageTitle: 'Advertising Window',
				breadcrumb: [
					{
						text: 'Device',
						to: '/device',
					},
					{
						text: 'Advertising Window',
						active: false,
					},
					{
						text: 'Edit Product Description',
						active: true,
					},
				],
			},
		},
		{
			path: '/materials',
			name: 'materials',
			component: () => import('@/views/admin/materials/List.vue'),
			meta: {
				navActiveLink: 'materials',
				pageTitle: 'Materials',
				breadcrumb: [
					{
						text: 'Materials',
						active: true,
					},
				],
			},
		},
		{
			path: '/materialsDetail',
			name: 'materialsDetail',
			component: () => import('@/views/admin/materials/Detail.vue'),
			meta: {
				navActiveLink: 'materials',
				pageTitle: 'Materials',
				breadcrumb: [
					{
						text: 'Materials',
						to: '/materials',
					},
					{
						text: 'Materials Detail',
						active: true,
					},
				],
			},
		},
		{
			path: '/materialsPreview',
			name: 'materialsPreview',
			component: () => import('@/views/admin/materials/Preview.vue'),
			meta: {
				navActiveLink: 'materials',
				pageTitle: 'Materials',
				breadcrumb: [
					{
						text: 'Materials',
						to: '/materials',
					},
					{
						text: 'Materials Preview',
						active: true,
					},
				],
			},
		},
		{
			path: '/program',
			name: 'program',
			component: () => import('@/views/admin/program/List.vue'),
			meta: {
				pageTitle: 'Program',
				breadcrumb: [
					{
						text: 'Program',
						active: true,
					},
				],
			},
		},
		{
			path: '/programDetail',
			name: 'programDetail',
			component: () => import('@/views/admin/program/Detail.vue'),
			meta: {
				navActiveLink: 'program',
				pageTitle: 'Program',
				breadcrumb: [
					{
						text: 'Program',
						to: '/program',
					},
					{
						text: 'Program Detail',
						active: true,
					},
				],
			},
		},
		{
			path: '/programEdit',
			name: 'programEdit',
			component: () => import('@/views/admin/program/Edit.vue'),
			meta: {
				navActiveLink: 'program',
				pageTitle: 'Program',
				breadcrumb: [
					{
						text: 'Program',
						to: '/program',
					},
					{
						text: 'Program Edit',
						active: true,
					},
				],
			},
		},
		{
			path: '/programPreview',
			name: 'programPreview',
			component: () => import('@/views/admin/program/Preview.vue'),
			meta: {
				navActiveLink: 'program',
				pageTitle: 'Program',
				breadcrumb: [
					{
						text: 'Program',
						to: '/program',
					},
					{
						text: 'Program Preview',
						active: true,
					},
				],
			},
		},
		{
			path: '/schedule',
			name: 'schedule',
			component: () => import('@/views/admin/schedule/List.vue'),
			meta: {
				pageTitle: 'Schedule',
				breadcrumb: [
					{
						text: 'Schedule',
						active: true,
					},
				],
			},
		},
		{
			path: '/scheduleDetail',
			name: 'scheduleDetail',
			component: () => import('@/views/admin/schedule/Detail.vue'),
			meta: {
				navActiveLink: 'schedule',
				pageTitle: 'Schedule',
				breadcrumb: [
					{
						text: 'Schedule',
						to: '/schedule',
					},
					{
						text: 'Edit',
						active: true,
					},
				],
			},
		},
		{
			path: '/schedulePublish',
			name: 'schedulePublish',
			component: () => import('@/views/admin/schedule/Publish.vue'),
			meta: {
				navActiveLink: 'schedule',
				pageTitle: 'Schedule',
				breadcrumb: [
					{
						text: 'Schedule',
						to: '/schedule',
					},
					{
						text: 'Publish',
						active: true,
					},
				],
			},
		},
		{
			path: '/scheduleScreen',
			name: 'scheduleScreen',
			component: () => import('@/views/admin/schedule/Screen.vue'),
			meta: {
				navActiveLink: 'schedule',
				pageTitle: 'Schedule',
				breadcrumb: [
					{
						text: 'Schedule',
						to: '/schedule',
					},
					{
						text: 'Screen',
						active: true,
					},
				],
			},
		},
		{
			path: '/member',
			name: 'member',
			component: () => import('@/views/admin/Member.vue'),
			meta: {
				pageTitle: 'Member',
				breadcrumb: [
					{
						text: 'Member',
						active: true,
					},
				],
			},
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/register',
			name: 'register',
			component: () => import('@/views/Register.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/forgot',
			name: 'forgot',
			component: () => import('@/views/ForgotPassword.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
