import Vue from 'vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

import Function from '@/components/public/Function'
Vue.component("Function", Function)

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN'
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
localize({ zh_CN });
